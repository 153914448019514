import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getObjDeepCopy } from '../../helpers/general';
import { IReply } from '../../interfaces/mailbox.interfaces';
import {
    IMessage,
    ISelectedMessage,
    IUnreadCount,
    ITrashedUnreadCount,
    IMailitemAction,
    ISelectedFolder,
    IFolder,
    IUpdatedFoldersAction,
    folderActions,
    ICountItem,
} from '../../interfaces';
import { filterAction, sortAction } from '../../enums';

const mailboxSlice = createSlice({
    name: 'mailbox',
    initialState: {
        selectedFolder: {
            id: undefined,
            name: undefined,
            parentId: undefined,
        },
        mailboxId: undefined,
        folderList: [],
        documentList: [],
        selectedDocuments: [],
        foldersUpdateAction: null,
        unreadCounter: null,
        trashedUnreadCounter: null,
        searchValue: '',
        searchInputText: '',
        sortOrder: sortAction.SORT_BY_DATE_NEWEST_TOP,
        filterBy: null,
        searchEnabledOutsideMailbox: false,
        editNoteDocument: undefined,
        selectedFolderAction: null,
        isMailboxMounted: false,
        mailItemAction: null,
        currentPage: 0,
        mailItemUpdated: false,
        reply: {
            threadReply: undefined,
            available: true,
            senderId: undefined,
            userId: undefined,
        },
        sendOpenReceipt: false,
    },
    reducers: {
        selectMailbox: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.mailboxId = payload;
            localStorage.setItem('mailboxId', `${payload}`);
        },
        selectFolder: (state, action: PayloadAction<ISelectedFolder>) => {
            const { payload } = action;
            sessionStorage.setItem('selectedFolderId', `${payload ? payload.id : null}`);

            state.selectedFolder = payload;
            state.selectedDocuments = [];
            state.documentList = [];
            state.searchValue = state.searchEnabledOutsideMailbox ? state.searchValue : '';
            state.searchEnabledOutsideMailbox = false;
        },
        setFolderList: (state, action: PayloadAction<IFolder[]>) => {
            const { payload } = action;
            state.folderList = payload;
        },
        setDocumentList: (state, action: PayloadAction<IMessage[]>) => {
            const { payload } = action;
            state.documentList = state.documentList.concat(payload);
        },
        replaceDocument: (state, action: PayloadAction<IMessage>) => {
            const { payload } = action;

            const indexToReplace = state.documentList.findIndex((doc) => doc.id === payload.id);
            if (indexToReplace > -1) {
                const documentList = getObjDeepCopy(state.documentList);
                documentList[indexToReplace] = payload;
                state.mailItemUpdated = true;
                state.documentList = documentList;
            }
        },
        fillDocumentList: (state, action: PayloadAction<IMessage[]>) => {
            const { payload } = action;
            state.documentList = payload;
        },
        cleanDocumentList: (state) => {
            state.documentList = [];
        },
        setSelectedDocuments: (state, action: PayloadAction<ISelectedMessage[]>) => {
            const { payload } = action;
            state.selectedDocuments = payload;
        },
        setFolderListUpdatedAction: (state, action: PayloadAction<IUpdatedFoldersAction>) => {
            const { payload } = action;
            state.foldersUpdateAction = payload;
        },
        updateMailboxDocument: (state, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            const docIndex = state.documentList.findIndex((doc) => doc.id === payload.id);
            const updateDocumentList = [...state.documentList];
            updateDocumentList[docIndex] = { ...updateDocumentList[docIndex], ...payload };

            state.documentList = updateDocumentList;
        },
        setUnreadCounter: (state, action: PayloadAction<IUnreadCount>) => {
            const { payload } = action;
            const unreadResult = payload;
            unreadResult.unreadCount = 0;
            unreadResult.items.forEach((item: ICountItem) => {
                unreadResult.unreadCount += item._count.id;
            });
            state.unreadCounter = unreadResult;
        },
        removeDocument: (state, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            const newDocumentList = state.documentList.filter((doc) => {
                return doc.id !== payload.id;
            });
            state.selectedDocuments = [];
            state.documentList = newDocumentList;
        },
        setTrashedUnreadCounter: (state, action: PayloadAction<ITrashedUnreadCount>) => {
            const { payload } = action;
            const trashedUnreadCountResult = payload;
            trashedUnreadCountResult.trashedUnreadCount = 0;
            trashedUnreadCountResult.items.forEach((item: ICountItem) => {
                trashedUnreadCountResult.trashedUnreadCount += item._count.id;
            });
            state.trashedUnreadCounter = trashedUnreadCountResult;
        },
        updateFolderName: (state, action: PayloadAction<{ folderId: number; folderName: string }>) => {
            const { folderId, folderName } = action.payload;
            const folderIndex = state.folderList.findIndex((folder) => folder.id === folderId);
            const updatedFolderList = [...state.folderList];
            updatedFolderList[folderIndex] = { ...updatedFolderList[folderIndex], name: folderName };
            state.folderList = updatedFolderList;
            state.selectedFolder = {
                id: updatedFolderList[folderIndex].id,
                parentId: updatedFolderList[folderIndex].parentId,
                name: updatedFolderList[folderIndex].name,
            };
        },
        removeDocumentById: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            const removedDocumentList = state.documentList.filter((doc) => {
                return doc.id !== payload;
            });
            state.selectedDocuments = [];
            state.documentList = removedDocumentList;
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.searchValue = payload;
        },
        setSortOrder: (state, action: PayloadAction<sortAction>) => {
            const { payload } = action;
            state.sortOrder = payload;
        },
        setFilterBy: (state, action: PayloadAction<filterAction>) => {
            const { payload } = action;
            state.filterBy = payload;
        },
        setSearchInputText: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.searchInputText = payload;
        },
        setSearchOutsideMailbox: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.searchEnabledOutsideMailbox = payload;
        },
        unselectDocumentById: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            const newSelectedDocumentsList = state.selectedDocuments.filter((doc) => {
                return doc.id !== payload;
            });
            state.selectedDocuments = newSelectedDocumentsList;
        },
        selectDocumentById: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            const newSelectedDocumentsListNotIncluded = state.selectedDocuments.filter(
                (selectedItem: ISelectedMessage) => {
                    return selectedItem.id !== payload;
                }
            );
            newSelectedDocumentsListNotIncluded.push({ id: payload } as ISelectedMessage);
            state.selectedDocuments = newSelectedDocumentsListNotIncluded;
        },
        setEditNoteDocument: (state, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            state.editNoteDocument = payload;
        },
        setSelectedFolderAction: (state, action: PayloadAction<folderActions>) => {
            const { payload } = action;
            state.selectedFolderAction = payload;
        },
        setIsMailboxMounted: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isMailboxMounted = payload;
        },
        setMailitemAction: (state, action: PayloadAction<IMailitemAction>) => {
            const { payload } = action;
            state.mailItemAction = payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.currentPage = payload;
        },
        clearItemUpdated: (state) => {
            state.mailItemUpdated = false;
        },
        setReplyInfo: (state, action: PayloadAction<IReply>) => {
            const { payload } = action;
            state.reply = {
                senderId: payload.senderId,
                userId: payload.userId,
                available: payload.available,
                threadReply: payload.threadReply,
            };
        },
        updateOpenReceipt: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.sendOpenReceipt = payload;
        },
        resetSendOpenReceipt: (state, action: PayloadAction<Array<number>>) => {
            const { payload } = action;
            state?.documentList.forEach((document) => {
                document?.artifacts.forEach((artifact) => {
                    if ((payload && payload.includes(artifact.id)) || !payload) {
                        artifact.sendOpenReceipt = false;
                    }
                });
            });
        },
    },
});

export const { reducer: mailboxReducer } = mailboxSlice;
export const {
    cleanDocumentList,
    clearItemUpdated,
    fillDocumentList,
    removeDocument,
    removeDocumentById,
    replaceDocument,
    selectDocumentById,
    selectFolder,
    selectMailbox,
    setCurrentPage,
    setDocumentList,
    setEditNoteDocument,
    setFilterBy,
    setFolderList,
    setFolderListUpdatedAction,
    setIsMailboxMounted,
    setMailitemAction,
    setSearchInputText,
    setSearchOutsideMailbox,
    setSearchValue,
    setSelectedDocuments,
    setSelectedFolderAction,
    setSortOrder,
    setTrashedUnreadCounter,
    setUnreadCounter,
    unselectDocumentById,
    updateFolderName,
    updateMailboxDocument,
    setReplyInfo,
    updateOpenReceipt,
    resetSendOpenReceipt,
} = mailboxSlice.actions;

export type IMailboxState = ReturnType<typeof mailboxReducer>;
