import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
    IDelegatedAccess,
    IDelegatedAccessAcceptRequest,
    IDelegatedAccessCreateRequest,
    IDelegatedDeleteRequest,
    IUserReferenceResponse,
} from 'src/interfaces';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';

const baseUrl = staticConfig.apiBaseUrl;
const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });
const tagTypes = ['DelegatedAccess'];

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const tokenRefresherQueueMiddleware = await import('src/helpers/tokenExpirationValidator').then((module) => {
        return new module.TokenRefresherQueueMiddleware();
    });
    await tokenRefresherQueueMiddleware.check();
    return await baseQuery(args, api, extraOptions);
};

export const delegatedAccessApi = createApi({
    reducerPath: 'delegatedAccessApi',
    baseQuery: baseQueryWithReauth,
    tagTypes,
    endpoints: (builder) => ({
        getDelegatedAccess: builder.query<IDelegatedAccess[], void>({
            query: () => {
                return `mailbox/access?onlyAccessor=true`;
            },
            providesTags: tagTypes,
        }),
        getDelegatedAccessor: builder.query<IDelegatedAccess[], number>({
            query: (mailboxId) => {
                return `mailbox/access?mailbox=${mailboxId}&onlyOwner=true`;
            },
            providesTags: tagTypes,
        }),
        createDelegatedAccess: builder.mutation({
            query: (request: IDelegatedAccessCreateRequest) => ({
                url: `mailbox/${request.mailboxId}/access/${request.userReference}`,
                method: 'POST',
                body: request.data,
            }),
            invalidatesTags: tagTypes,
        }),
        getUserReference: builder.query<IUserReferenceResponse, void>({
            query: () => {
                return `mailbox/access/userReference`;
            },
            providesTags: tagTypes,
        }),
        deleteDelegatedAccess: builder.mutation({
            query: ({ mailboxId, userReference }: IDelegatedDeleteRequest) => {
                return {
                    url: `mailbox/${mailboxId}/access/${userReference}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: tagTypes,
        }),
        acceptDelegatedAccess: builder.mutation({
            query: ({ mailboxId, userReference, data }: IDelegatedAccessAcceptRequest) => {
                return {
                    url: `mailbox/${mailboxId}/access/${userReference}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: tagTypes,
        }),
    }),
});
export const {
    useGetDelegatedAccessQuery,
    useGetDelegatedAccessorQuery,
    useCreateDelegatedAccessMutation,
    useGetUserReferenceQuery,
    useDeleteDelegatedAccessMutation,
    useAcceptDelegatedAccessMutation,
} = delegatedAccessApi;
