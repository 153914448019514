import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguage } from '../../interfaces/globalization.interfaces';
import { ICountryCode } from '../../interfaces/contact.interfaces';
import { IOrganization } from '../../interfaces';

const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        name: undefined,
        registrationNumber: undefined,
        countryId: undefined,
        category: undefined,
        defaultLanguageId: undefined,
        logoId: undefined,
        canSendMessages: undefined,
        canSendInterimMessages: undefined,
        canSendSigningMessages: undefined,
        id: undefined,
        supportedLanguages: undefined,
        portalLanguages: [],
        globalAvailableLanguages: [],
        parentId: undefined,
        countryCodes: undefined,
    },
    reducers: {
        setSupportedLanguages: (draft, action: PayloadAction<ILanguage[]>) => {
            const { payload } = action;
            draft.supportedLanguages = payload;
        },
        setPortalLanguages: (draft, action: PayloadAction<ILanguage[]>) => {
            const { payload } = action;
            draft.portalLanguages = payload;
        },
        setGlobalAvailableLanguages: (draft, action: PayloadAction<ILanguage[]>) => {
            const { payload } = action;
            draft.globalAvailableLanguages = payload;
        },
        setCountryCodes: (draft, action: PayloadAction<ICountryCode[]>) => {
            const { payload } = action;
            draft.countryCodes = payload;
        },
    },
});

export const { reducer: organizationReducer } = organizationSlice;
export const { setCountryCodes, setSupportedLanguages, setPortalLanguages, setGlobalAvailableLanguages } =
    organizationSlice.actions;

export interface IOrganizationState
    extends IOrganization,
        Omit<ReturnType<typeof organizationReducer>, keyof IOrganization> {}
