import { SystemStyleObject } from '@chakra-ui/react';

export const Switch: SystemStyleObject = {
    baseStyle: {
        thumb: {
            bg: 'white',
        },

        track: {
            bg: 'gray.300',
        },
    },
    variants: {
        solid: {
            track: {
                _checked: {
                    bg: 'green.700',
                },
            },
        },
    },
    defaultProps: {
        colorScheme: 'green',
        variant: 'solid',
        size: 'lg',
    },
};
