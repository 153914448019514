export enum MailboxAccessRoles {
    OWNER = 1,
    READWRITE = 2,
    READONLY = 3,
}

export enum AccessLevel {
    READONLY = 0,
    READMODIFYDELETE = 0x03,
}

export enum AccessStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    EXPIRED = 'expired',
}
