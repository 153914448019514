// Interfaces
import { IPortalConfig, IPortalLink, IPortalLogo, IPortalText } from '../interfaces/config.interfaces';

export const defaultConfig: IPortalConfig = {
    links: Array<IPortalLink>(),
    texts: {
        product: {
            name: {
                defaultTranslationKey: 'PRODUCT_NAME',
            } as IPortalText,
        },
    },
    logos: Array<IPortalLogo>(),
    graphlQL: {
        isDisabled: true,
    },
    features: {
        quota: {
            configuration: false,
        },
        data: {
            export: false,
        },
        user: {
            delete: false,
        },
        message: {
            reply: false,
            forward: false,
            archive: false,
            move: false,
            delete: false,
            save: false,
            print: false,
            read: false,
            rename: false,
            note: {
                add: false,
            },
            information: false,
            signing: false,
            openReceipt: false,
        },
        folder: {
            customFolders: true,
        },
        interim: {
            importPopup: false,
            interimActivated: false,
        },
        organization: {
            onboard: false,
        },
        legal: {
            documents: false,
        },
        mailbox: {
            delegatedAccess: false,
        },
        organisation: {
            welcomeLetter: false,
        },
        sender: {
            mandatory: false,
        },
    },
};
